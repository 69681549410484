.detailcovercustom {
    position: absolute;
    left: -40px;
    top:19px;
    
}

.input{
    left: 10px;
    margin-top :-11px;
}
.button-left{
    margin-Right:4px;
}
            
        
    
