.app-wrapper{
  .wrapper-container{
      border: 1px solid #dcdcdc;
      padding: 20px;
      background-color: #fff;
      position: relative;
      /* overflow: auto; */
  }
  .e404{ cursor: pointer; text-decoration: underline;}
  .uppercase{ text-transform: uppercase;}
  .btn-default{
    background-color: #bfbfbf !important;
  }
  .word-break{
    word-break: break-word;
  }
  
  .breadcrumb-box{
    padding-top: 5px;
    //margin-bottom: 10px;
  }
  .blinking{
    animation:blinkingText 1s infinite;
  }
  @keyframes blinkingText{
    0%{		color: #000;	}
    50%{	color: transparent;	}
    100%{	color: #000;	}
  }
  /* .popupUI{ margin-top: 0% !important;} */
  .overlayer {
      z-index: 9;
      position: absolute;
      background-color: rgba(255,255,255, .5);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
  }
  .auto-center{ margin: auto; position: absolute; top: 0; bottom: 0; left: 0; right: 0;}
  .mb-20{ margin-bottom: 20px;}
  .colorwhite{
    background-color: #fff;
  }
  .editableDiv div{
      background-color: grey;
      padding: 2px 8px 2px!important;
      color: white;
  }
  .editableDiv svg{
      margin: 5px;
  }
  .pointer{
    cursor: pointer;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #dc1e38;
  }
  @media (max-width: 991px) {
    .sidemenubox{
      position: absolute;
      margin-top: 4.1rem;
    }
  }
  @media (max-width: 767px) {
    .sidemenubox{
      margin-top: 4.1rem;
    }
  }

  @media (min-width: 551px) and (max-width: 660px) and (orientation: landscape) {
      .offset-sm-1 {
        margin-left: 1.33333%;
        max-width: 100%;
        flex: 0 0 98%;
      }
  }
  @media (min-width: 576px) and (max-width: 768px) {
    .offset-sm-1 {
        margin-left: 0.33333%;
    }
  }
  @media (min-width: 200px) and (max-width: 550px) and (orientation: landscape) {
    .offset-sm-1 {
      max-width: 100%;
      flex: 0 0 98.66667%;
    }
  }
  @media (min-width: 576px){
      .col-sm-11 {
          flex: 98.66667%;
          max-width: 100%;
      }
  }
}

@media (max-width: 480px){
  .search-area {
    margin-bottom: 20px!important;
  }
}

.box-login{
  .card-body{
    overflow: hidden;
  }
}
.pointer{cursor: pointer;}
.login-link{    font-weight: 600;}