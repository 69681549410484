.dashed{ border-bottom: 1px dashed #999}
.red-dashed{ border-bottom: 1px dashed #f00; color: #f00;}
.highlighted-row{
    background-color: #ffffb3 !important;
}
.highlighted-row .btn{
    vertical-align: middle;
    margin-top: 0% !important;
}
.clearbtn{
    margin: 0px;
    border: 0px currentColor !important;
    border-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}
.tracker-details{
    margin-bottom: 10px;
    cursor: pointer;
}