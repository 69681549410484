.workreport{
    .text-wrap{
        word-break: break-all;
    }
    .eqpdata{
        border: 1px solid #ccc;
        padding: 1rem 1rem 1rem;
        .eqpLogo{
            width: 46px;
            max-width: 46px;
            border-radius: 41px;
            border: 1px solid #ccc;
            height: 46px;
            max-height: 46px;
            float: left;
        }
        .tractord{
            background-color: #0000008a;
            color: white !important;
            float: left;
            text-transform: uppercase;
            padding: 0.3rem 1rem 0.3rem;
            margin: 0 auto;
            margin-top: 0.6rem;
        }
        .details{
            padding: 0px;
            .title{
                font-weight: bold;
            }
            div{
                color: black;
            }
        }
    }
    .cardCover{
        width: 100%;
        border: 1px solid #ccc;
        margin: 0.2rem;
        padding: 0.6rem;
        .selectedMonth{
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: bold;
            color: #dc1e38;
        }
        .reportstype{
            text-transform: uppercase;
            font-size: 1.1rem;
            color: #dc1e38;
            padding-top: 1.5rem;
        }
        .reportstypelist{
            font-size: 2rem;
            .form-check{
                padding: 0rem;
                .custom-checkbox{
                        text-transform: uppercase;
                        font-size: 1rem;
                        /* margin:0.9rem 0rem 0.9em; */
                        
                        
                            float: left;
                            margin-right: 8px !important;
                        
                }
            }
        }
    }
    .calLogo {
        position: absolute;
        border: 0;
        display: inline-flex;
        top: 9px;
        right: 8px;
        font-size: 1.5rem;
        background-color: white;
        padding: 0;
    }
}

.menu-item {
    padding: 3px 9px;
    margin: 5px 4px;
    user-select: none;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
}
.menu-item-wrapper{
    border-radius: 23px;
    border: 1px solid #dee2e6;
    margin: 0.1rem;
}
.menu-item-wrapper.active {
    background-color: #dc1e38;
    border: 0px solid white;
    font-size: 0.9rem;
    color: white;
}
.scroll-menu-arrow {
    padding: 15px 14px 16px;
    cursor: pointer;
    margin-top: -13px;
    font-size: 3rem;

}

.reports{
  select{
    padding: 0.2rem 0.8rem;
  }
}

.scrollmenu{
    width: 100%;
    .menu-wrapper--inner{
        max-width: 712px !important;
    }
    .horizontal-menu{
        .menu-wrapper{
            margin-top: -0.3rem;
        }
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
            .menu-wrapper{
                margin-top: 1.3rem;
                overflow-x:scroll;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                -ms-overflow-x: scroll!important;
                height: 67px;
            }
        }
    }
}