.calLogoContract {
    position: absolute;
    border: 0;
    display: inline-flex;
    top: 9px;
    right: 8px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
}

.calLogoContractTractor {

    border: 0;
    display: inline-flex;
    top: 9px;
    right: 8px;
    font-size: 1.5rem;
    background-color: white;
    padding: 0;
    transform: translateX(-28px);
}

.react-datepicker__time-list {
    padding: 0;
    padding-right: 0px !important;
}

.sp-topRow {
    .exclamationt {
        height: 20px;
    }
}

.calculated {
    background-color: white;
    border: none;
    padding: 0.65rem 0.8rem;
    line-height: 1.25;
    font-size: 1rem;
    //margin-left: -96px;
    font-size: medium;
    width: 100px
}

.operator {
    margin-top: 26px;
}

.selected {
    background-color: #e0e0e0;
    border-color: #cbc2c2;
    border-block-width: 1px;
    border-right-width: 1px;
    //color:black;
    font-weight: bold;
}

.unselected {
    background-color: white;
    border-color: #cbc2c2;
    border-block-width: 1px;
    border-right-width: 1px;
    // color:black;
}
.containerbox {
    position: relative;
    display: inline-block;
}

.select {
    margin-bottom: -10px;
    width: 115px; 
}
.selectType {
    margin-bottom: -5px;
    width: 90px;
}
select.form-control:not([size]):not([multiple]) {
    //height: 0;
    padding: 0 0;
}

.yearmonth {
    padding-bottom: 5px;
    padding-top: 5px;
}

.tooltip1 {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }
  
  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 170px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip1 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 35%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip1:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltipicon {
    background-color: gray;
    border-radius: 50%;
    padding: 0.1em 0.2em;
    color: white;
    display: inline-block;
    font-size: 10px;
    transition: font-size 0.2s;
  }

  .tooltipicon:hover{
    font-size:12px;
  }

p.ridge {
    border-style: ridge;
}

.toptabingbar{
    width: 100%;
    margin-bottom: 22px;
    border-bottom: 2px solid #e8e8e8;
    div{
        margin-bottom: -0.9px;
        font-size: 18px;
        float: left;
        color: #464a4e;
        padding-right: 18px;
        padding-left: 18px;
        font-weight: 500;
        border-bottom: 1px solid #9c9b9b;
        box-shadow: 0px 1px 0px 0px #ccc;
        padding-bottom: 8px;
        cursor: pointer;
    }
    .active{
        border-bottom: 1px solid #dc1e38;
        color: #dc1e38;
    }
}
