.equipmentalertlist{
  .callScheduled {
      width: 20px;
      margin-left: 15px;
  }
  .urgencyColor1{
    color: #dc1e38;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .urgencyColor2{
    color: #28a745;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .urgencyColor3{
    color: #ffc107;
    font-size: 20px;
    padding-top: 4px;
    margin-left: 9px;
    margin-right: -5px;
  }
  .widthmore{
    width: 165px;
    img{
      margin-right: 3px;
    }
    button{
      margin-top: 1px;
    }
  }
  .inprogress{
    width: 25px;
    margin-left: 13px;
  }
  .completed{
    width: 20px;
  }
  .notstart{
    width: 20px;
    margin-left: 13px;
  }
  .callout{
    p{
      margin-bottom: 1.4px;
      text-align: center;
    }
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}


