.tabledashboard{
	border: solid 1px #eeeeee;
    background-color: white!important;
    .extrawidth{
        width: 230px;
        text-align: center;
    }
    overflow-y: scroll;
    max-height: 500px;
}
.tabledashboard {
    thead tr th {
            font-size: 0.8em;
            position: relative;
            height: 25px;
            th{
                border: 0;
                font-size: 0.8rem;
                padding: 0;
                padding-top: 0rem;
          }
            svg {
                font-size: 1.2rem;
                position: absolute;
                bottom: 6px;
                pointer-events: none;
            }
    
            box-sizing: content-box;
    
            &:first-child {
                //width: 90px;
            }
    
            &:nth-of-type(3n) {
                //width: 100px;
            }
        }
        .width-edit{
            button, input, optgroup, select, textarea{
              height: 21px;
            }
           
            } 
        tbody {
            tr {
                cursor: pointer;
                td {
                    background-color: white;
                    border-bottom: 1px solid #e8e8e8;
                    position: relative;
                    //vertical-align: unset;
                    height: 30px;
                    max-height: 30px;
    
                    .noneditable {
                        background-color: white;
                        color: black;
                        border: 0;
                        padding: 2px 3px 2px;
                        width: 60px
                    }
    
                    .edit {
                        background-color: white;
                        padding: 2px 3px 2px;
                        width: 60px;
                        height: 20;
                        max-height: 20;
                    }
    
                    .faicon .red {
                        margin-top: 8px;
                    }
    
                    button {
                        margin-top: -11px;
                    }
    
                    .closeit {
                        background: white;
                        border: 0px;
                        margin: 0px;
                    }
    
                    select {
                        padding: 5px 0 5px 5px;
                    }
    
                    .statuse {
                        width: 2.5rem;
                    }
    
                    .urgencyColor1 {
                        margin: 4px;
                    }
    
                    .urgencyColor2 {
                        margin: 4px;
                    }
    
                    .urgencyColor3 {
                        margin: 4px;
                    }
                    .react-datepicker__input-container{
                        input{
                            padding: 5px 1px 6px;
                            border: 1px solid #ced4da;
                        }
                      }
                      .selectwrapper{
                        select{
                          padding: 0rem;
                        }
                      }
                      .react-datepicker__time-list{
                          padding: 0;
                          padding-right: 0px!important;
                      }
                      input{
                        background-color: white;
                        padding: 8px 3px 7px;
                      }
                }
            }
    
            .alertbellcover {
                .alertpopup {
                    background-color: white;
                    border: 0.02rem solid #dad8d8;
                    max-width: 19rem;
                    padding: 4px;
                    margin: -3.4rem 2.1rem 0rem;
                    z-index: 9;
                    min-width: 19rem;
    
                    .innerrowscroll {
                        max-height: 340px;
                        overflow-y: scroll;
                    }
                }
    
                .fns11 {
                    font-size: 11px;
                }
    
                .fonbox {
                    font-size: 12px;
                    border-bottom: 0.1rem solid #dee2e6;
                    padding: 0.3rem 0.5rem 0.3rem;
                    padding-bottom: 0.1rem;
                    color: black;
                    margin-bottom: 0.2rem;
                }
    
                .notread {
                    background-color: #eeeeee !important;
                }
            }
        }
    .tractor{
        width: 45px;
        height: 45px;
        margin-right: 6px;
    }
    .rounded-circle{
        border: 0.003rem solid #e2ddde;
    }
    .numberplate{
        min-width: 142px;
    }
    .mappointer{
        img{
            width: 2.6rem;
        }
    }
    .exclamationt{
        width: 30px;
    }
    .alertbell{
        width: 30px;
    }
    .alerttext{
        position: absolute;
        margin: -40px 19px 0px;
        color: white;
        background-color: red;
        border-radius: 50%;
        padding: 4px 1px 4px 3px;
        font-size: 10px;
        width: 23px;
        height: 23px;
    }
    .myspecialth{
        font-size: 21px!important;
        text-transform: uppercase;
        color: #dc1e38;
        font-weight: 600;
        display: none;
    }
    .numberplate{
        input{
            background-color: #efeaea;
            height: 2rem;
            max-width: 10rem;
        }
    }    
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .xs-hidden{
    display: none;
  }
  .xs-show{
    display: inline;
  }
  .xs-show-table{
    display: table-cell;
  }
}

