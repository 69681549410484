.AddsysMaintaintcontainer .error  {
    display: none;
}
.AddsysMaintaintcontainer{
    .eqdetailbox{
        .detailcover{
            padding: 1rem;
            .react-datepicker__time-list{
                padding: 0;
                padding-right: 0px!important;
            }
            .title{
                width: 95px;
                font-weight: bold;
                margin-top: 5px;
            }
            .calLogo{
                float: left;
                font-size: 1.5rem;
                margin: 0.1rem 0rem 0.1rem 5px;
            }
            .selectwrapper{
                select{
                    padding: 0rem;
                }
            }
            .width70{
                width: 70%;
            }
            .fullwidth{
                width: 100%;
            }
            textarea{
                height: 144px;
                resize: none;
                border-color: #ced4da;
            }
        }
    }
}
