.c-manage-service-company {
    .companysearch{
        width: 100%;
        margin-bottom: 20px;
    }
    h3 {
        margin-bottom: 30px;
        padding: 0;
    }
    select.form-control:not([size]):not([multiple]) {
        height: auto;
        padding: 5px 0;
    }
    .hide {
        display: none;
    }
    button {
        &.btn-danger {
            margin-right: 8px;
        }
        &.btn-dark {
            margin-right: 0 !important;
        }
        &.edititnow{
             border: 0;
            background: white;
        }
    }
    .tabledashboard tbody tr td button {
        margin-top: 0 !important;
    }
}