.c-field-information {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    @media (min-width:768px) {
        height: auto;
    }
    & {
        &-header {
            display: none;
            @media (min-width:768px){
                display: flex;
            }
            .search-equipment {
                .btn-search-equipment {
                    width: 200px;
                    margin-left: auto;
                }
            }
        }
        &-body {
            padding-bottom: 20px;
            z-index: 1;
            display: inline-block;
            margin: 0 20px;
            @media (min-width:768px){
                z-index: auto;
                display: flex;
                margin: 0;
            }
            .left {
                display: flex;
                flex-direction: row;
                max-width: 100%;
                background: #fff;
                border-radius: 5px;
                padding: 15px;
                    @media (min-width: 768px){
                        background-color: transparent;
                        border-radius: none;
                        padding: inherit;
                    }
                p{
                    font-weight: bold;
                    margin: 0;
                    padding-right: 30px;
                }
                select {
                    cursor: pointer;
                    option {
                        &:disabled {
                            background-color: #ccc;
                            color: #333;
                        }
                    }
                }
            }
            .right {
                max-width: 100%;
                margin-top: 20px;
                padding: 0;
                text-align: right;
                @media (min-width: 768px){
                    margin-top: auto;
                    padding: auto;
                    max-width: 50%;
                }
            }
            p {
                &.success {
                    color: green;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
        &-cta {
            padding-bottom: 20px;
            z-index: 1;
            position: absolute;
            bottom: 0;
            margin: 0;
            @media (min-width:768px) {
                z-index: auto;
                position: relative;
                bottom: auto;
                margin: 0 -15px;

            }
            .search-location {
                justify-content: flex-start;
                padding:0;
                .search-city{
                    box-sizing: border-box;
                    border: 1px solid #ced4da;
                    width: 240px;
                    height: 32px;
                    padding: 5px 15px;
                    border-radius: 20px;
                    font-size: 14px;
                    outline: none;
                    text-overflow: ellipsis;
                    top: 70px !important;
                }
            }
            .all-cta {
                display: inline-block;
                padding: 0;
                @media (min-width:768px){
                    display: flex;
                    justify-content: flex-end;
                    padding: 0 15px;
                }
                button {
                    margin-right: 20px;
                    &:last-child {
                        margin-right:0;
                    }
                }
            }
        }
        &-map-area {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            @media (min-width:768px) {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
            }
            .map {
                .gm-style-mtc {
                    display: none;
                    @media (min-width:768px) {
                        display: block;
                    }
                }
                .gm-svpc {
                    display: none;
                    @media (min-width:768px) {
                        display: block;
                    }
                }
                .gmnoprint {
                    display: none;
                    @media (min-width:768px) {
                        display: block;
                    }
                }
                .gm-control-active {
                    &.gm-fullscreen-control {
                        display: none;
                        @media (min-width:768px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}