.noPadding {
  padding-right: 0px !important;
  padding-left: 0px !important; 
}
.ssa{
  .inprogress{
    width: 15px;
    margin-left: 13px;
  }
  .completed{
    width: 15px;
  }
  .notstart{
    width: 15px;
    margin-left: 13px;
  }
  .callout{
    p{
      margin-bottom: 1.4px;
      text-align: center;
    }
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .xs-hidden{
        display: none;
    }
    .xs-show{
        display: inline;
      }
    .xs-show-table{
        display: table-cell;
      }
}


