.btn-move-setting{
    //text-align: left; 
    position:absolute;
    bottom:0;                          
    left:0; 
}

.auto-index {
    counter-reset: Serial; /* Set the serial number counter to 0 */
   
   }

.auto-index td:first-child:before{
    counter-increment: Serial;      /* Increment the Serial counter */
    content: counter(Serial); /* Display the counter */
}

table.scroll tbody,
table.scroll thead { display: block;
width: 100% }

table.scroll tbody {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    
}

tbody td, thead th { 
    width : 210px; 

  } 
  





