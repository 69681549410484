.eq-holder{
    padding: 0px !important;
    float: left;
    .view-all{
        text-align: right;
        margin-bottom: 5px;
        a{
            color: #929292;
            font-weight: 700;
            font-size: 12px;
        }
    }
    .bodded{
        border: 1px solid #dcdcdc;
    }
    .left-box{
        padding: 20px;
        .setting-box{
            padding: 20px;
            background-color: #fff;
            display: flex;
            width: 100%;
            h3{
                margin: 0;
            }
        }
        
        .h3-links{
            font-size: 12px;
            text-transform: lowercase;
            float: right;
            display: block;
            margin-top: 5px;
            color: #929292;
            a{
                color: #929292
            }
        }

        .manage-reports{
            padding: 20px;
            background-color: #fff;
            display: flex;
            svg{
                color: #dc1e38;
                font-size: 5.4rem;
            }
            .reports-name{
                list-style-type: none;
                text-transform: uppercase;
                font-weight: 600;
                padding: 0;
                margin: 0;
                color: #444;
                li{
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    table{
        margin-bottom: 0px;
    }
}
.holdright{
    border: none;
    h3{
        margin: 0px 0 10px 0;
    }
}

.db-section{
    padding: 0 !important;
    border: none !important;
    background: none !important;
}

.pad-0{
    padding: 0 !important;
}
